import React from "react";
import "../../styles/components/Contact.scss";

function Contact() {
  return (
    <section className="contact" id="contact">
      <h2 className="section-header">CONTACT</h2>
      {/* <h1>Contact</h1> */}
      <div className="contact-links">
        <a
          className="contact-links__btn"
          href="https://github.com/tonybatty"
          target="_blank"
        >
          <i className="fab fa-github" />
        </a>
        <a
          className="contact-links__btn"
          href="https://www.linkedin.com/in/tonybatty/"
          target="_blank"
        >
          <i className="fab fa-linkedin" />
        </a>
        <a className="contact-links__btn" href="mailto:hello@tonybatty.com">
          <i className="fas fa-envelope-square" />
        </a>
      </div>
      <p className="contact__credit">
        Site made by <b className="contact__credit__link">Tony Batty</b>
      </p>
    </section>
  );
}

export default Contact;
