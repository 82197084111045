import React from "react";
import PortfolioItem from "./PortfolioItem";
import "../../styles/components/Portfolio.scss";

function Portfolio(clickViewSite, clickGithub) {
  const portfolio = {
    1: {
      title: "OpenTab",
      subtitle:
        "A web application for tracking and settling rounds between friends.",
      text:
        "This was a full-stack group project in which I worked on designing the user interface in figma, and coded the components in JSX, and styled with SCSS using BEM naming conventions throughout.",
      link: "http://team-open-tab.herokuapp.com/",
      github: "https://github.com/Team-OpenTab/opentab",
      imgSrc: "opentab.png",
      bgColor: "#0875a5",
      display: true
    },
    2: {
      title: "DeliverEat",
      subtitle:
        "A responsive web app which allows customers to order food online.",
      text:
        "This was a full-stack web app which I used Node.js for the server and created a database in postgreSQL and made a RESTful API. For the front end I used React and stylised with SCSS, and utilised BEM and Flexbox.",
      link: "https://takeaway-menu.herokuapp.com/",
      github: "https://github.com/tonybatty/delivereat-with-db",
      imgSrc: "delivereat.png",
      bgColor: "#6CB881",
      display: true
    },
    3: {
      title: "Movie Search",
      subtitle: "A movie search engine which uses the Open Movie Database API.",
      text:
        "I created this project with React, and stylised with CSS, and Flexbox to create a fully responsive web app. I utilised localStorage to save favourites locally and implemented pagination to navigate between movies in the search results.",
      link: "https://movie-search.netlify.com/",
      github: "https://github.com/tonybatty/movie-search",
      imgSrc: "movieSearch.png",
      bgColor: "#0875a5",
      display: true
    },
    4: {
      title: "Responsive News Reader",
      subtitle:
        "A responsive news reader website which displays the latest news retrieved from News API.",
      text:
        "This was created with pure JavaScript, HTML and CSS. My main goal was to make a fully responsive web app which looks great for all devices. I started with the mobile view first and used a card style interface. For larger desktop screens, the cards are displayed in a grid with the main article taking full horizontal space. Offers functionality to categorise news and search by keyword.",
      link: "https://responsive-news-reader.netlify.com/",
      github: "https://github.com/tonybatty/responsive-news-reader",
      imgSrc: "responsiveNewsReader.png",
      bgColor: "#F76F6B",
      display: true
    }
  };

  return (
    <section className="portfolio" id="portfolio">
      <h2 className="section-header">PORTFOLIO</h2>
      {/* <h1 className="portfolio-title">Portfolio</h1> */}
      {Object.keys(portfolio).map(key => (
        <PortfolioItem
          key={key}
          projectId={key}
          title={portfolio[key].title}
          subtitle={portfolio[key].subtitle}
          text={portfolio[key].text}
          link={portfolio[key].link}
          github={portfolio[key].github}
          imgSrc={portfolio[key].imgSrc}
          bgColor={portfolio[key].bgColor}
          clickViewSite={clickViewSite}
          clickGithub={clickGithub}
        />
      ))}
    </section>
  );
}

export default Portfolio;
