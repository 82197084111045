import React from "react";
import "../../styles/components/LandingPage.scss";

function LandingPage() {
  return (
    <section className="landingPage" id="home">
      <div className="title">
        <h1 className="title__heading">
          Hello! I'm Tony Batty.
        </h1>
        <h3 className="title__subheading">I’m a front-end web developer.</h3>
      </div>
      <div className="arrow bounce">
        <a className="fas fa-chevron-down" href="#about"/>
      </div>
    </section>
  );
}

export default LandingPage;
