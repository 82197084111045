import React from "react"
import cx from "classnames"
import vhCheck from "vh-check"
import LandingPage from "./LandingPage"
import Portfolio from "./Portfolio"
import Contact from "./Contact"
import About from "./About"
import "../../styles/components/Nav.scss"
import "../../styles/components/App.scss"

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isTop: true
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    vhCheck()
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 20
      if (isTop !== this.state.isTop) {
        this.handleScroll(isTop)
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll(isTop) {
    this.setState({ isTop })
  }

  render() {
    const navClasses = cx("nav", {
      "nav--scroll": !this.state.isTop
    })

    const menuItemClasses = cx("menu__item", {
      "menu__item--scroll": !this.state.isTop
    })

    const logoImg = this.state.isTop
      ? "../static/images/logoLight.png"
      : "../static/images/logoDark.png"

    const logoTextClasses = cx("logo__text", {
      "logo__text--scroll": !this.state.isTop
    })

    return (
      <div className="app">
        <div className={navClasses}>
          <div className="logo">
            <img className="logo__img" src={logoImg} />
            <h4 className={logoTextClasses}>Tony Batty</h4>
          </div>
          <nav className="menu">
            <a className={menuItemClasses} href="#home">
              HOME
            </a>
            <a className={menuItemClasses} href="#portfolio">
              PORTFOLIO
            </a>
            <a className={menuItemClasses} href="#contact">
              CONTACT
            </a>
          </nav>
        </div>
        <LandingPage />
        <Portfolio
          clickViewSite={this.clickViewSite}
          clickGithub={this.clickGithub}
        />
        <Contact />
      </div>
    )
  }
}

export default App
