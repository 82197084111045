import React from "react";
import "../../styles/components/PortfolioItem.scss";

function PortfolioItem({
  title,
  subtitle,
  text,
  link,
  github,
  imgSrc,
  bgColor,
  clickViewSite,
  clickGithub
}) {
  return (
    <div className="portfolio-item">
      <div className="portfolio-screenshot">
        <img
          className="portfolio-screenshot__image"
          src={`../static/images/${imgSrc}`}
        />
      </div>
      <div className="portfolio-info">
        <h1 className="portfolio-info__title">{title}</h1>
        <h3 className="portfolio-info__subtitle">{subtitle}</h3>
        <p className="portfolio-info__text">{text}</p>
        <div className="portfolio-info__buttons">
          <a className="portfolio-info__buttons__btn" href={link} target="_blank">
            <i className="fas fa-external-link-alt" />
            &nbsp;&nbsp;VIEW SITE
          </a>
          <a className="portfolio-info__buttons__btn" href={github} target="_blank">
            <i className="fab fa-github" />
            &nbsp;&nbsp;GITHUB
          </a>
        </div>
      </div>
    </div>
  );
}

export default PortfolioItem;
